import React, {
  useState,
  useCallback,
  useEffect,
  Fragment,
  useContext,
} from "react";

import { EntryForm, Section, Header, Title } from "./styles";

import {
  InputField,
  CheckBoxField,
  SelectField,
  //AutoCompleteField,
} from "basics";

import { ImageUploader } from "components/ImageUploader";

import VideoPlayer from "components/VideoPlayer";

import FileUploader from "components/FileUploader";

import Questionnaire from "components/Questionnaire";

import { ComponentContainer, HorizontalLine, ComponentTitle } from "App/styles";

import { useForm, useFieldArray } from "react-hook-form";

import FormLoader from "loader/FormLoader";

import { Grid, Col, Row } from "react-styled-flexboxgrid";

import useEntryForm from "hooks/useEntryForm";

import useScroll from "hooks/useScroll";

import FormToolBar from "components/FormToolBar";

import { FormStatus } from "model/dictionary";

import { AlertContext } from "contexts/AlertContext";

import { States, StaffPositions } from "model/options";
import FacebookLineIcon from "icons/FacebookLineIcon";
import LinkedinLineIcon from "icons/LinkedinLineIcon";
import InstagramLineIcon from "icons/InstagramLineIcon";
import TwitterLineIcon from "icons/TwitterLineIcon";
import WebLineIcon from "icons/WebLineIcon";
import PhoneLineIcon from "icons/PhoneLineIcon";
import Survey from "components/Survey";
import { object } from "yup";
import { yupResolver } from "@hookform/resolvers";

export default function Form({
  onCompleted,
  onClose,
  form,
  loading,
  readOnly,
  currentStatus,
}) {
  const [showPostulator, setShowPostulator] = useState(
    (form && form.showPostulator) || false
  );

  const [showSurvey, setShowSurvey] = useState(false);

  const { SaveForm, UpdateForm, schema } = useEntryForm();
  const { scrollRef, scrollToRef } = useScroll();
  const { showWaiting, hideWaiting } = useContext(AlertContext);

  const fileUploaderTitle = readOnly
    ? "Archivos adicionales"
    : "Si es necesario puede subir archivos adicionales";

  const VideoLinkTitle = readOnly
    ? "Video de la postulacion"
    : "Sumá un video elevator pitch a tu postulación ";

  const { register, handleSubmit, errors, setValue, watch, reset, control } =
    useForm({
      resolver: yupResolver(object(schema)),
      defaultValues: form,
    });

  const { fields } = useFieldArray({
    control,
    name: "sections",
  });

  useEffect(() => {
    scrollToRef();
  }, [scrollToRef]);

  useEffect(() => {
    reset(form);
  }, [form, reset]);

  const onFormSubmitted = (data) => {
    setShowSurvey(false);
    onCompleted();
  };

  const onSubmit = (data) => {
    showWaiting("Actualizando formulario...");

    window.scrollTo(0, scrollRef.current.offsetTop);

    form.email = data.email;

    SaveForm(data, form, FormStatus.DRAFT).then(() => {
      hideWaiting();

      if (form.hasSurvey) {
        setShowSurvey(true);

        return;
      }

      onCompleted();
    });
  };

  const onUpdateForm = (nextStatus) => {
    showWaiting("Actualizando formulario...");

    window.scrollTo(0, scrollRef.current.offsetTop);

    UpdateForm(form, nextStatus).then(() => {
      hideWaiting();

      onCompleted(nextStatus);
    });
  };

  const handleShowPostulator = useCallback((value) => {
    setShowPostulator(value);
  }, []);

  const formTitle = form.award && form.award.title ? form.award.title : " ";

  return (
    <>
      {showSurvey && (
        <Survey
          onClose={onFormSubmitted}
          showSurvey={showSurvey}
          email={form.email}
        />
      )}
      <ComponentContainer
        ref={scrollRef}
        style={{ marginTop: "0px", position: "relative" }}
      >
        <ComponentTitle>{formTitle}</ComponentTitle>

        {/* <button onClick={() => console.log(watch(), form, errors)}>
          Watch
      </button> */}
        <EntryForm onSubmit={handleSubmit(onSubmit)}>
          <Section>
            <Header>
              <Title>Datos de la postulación</Title>
            </Header>
            {loading ? (
              <FormLoader />
            ) : (
              <Grid style={{ width: "100%", padding: "35px" }}>
                <input
                  type="hidden"
                  ref={register}
                  id="identity"
                  name="identity"
                  value={form.identity}
                />
                <input
                  type="hidden"
                  ref={register}
                  id="status"
                  name="status"
                  value={form.status}
                />

                <input
                  type="hidden"
                  ref={register}
                  id="minifiedHeader"
                  name="minifiedHeader"
                  value={form.minifiedHeader || false}
                />

                <input
                  type="hidden"
                  ref={register}
                  id="hasSurvey"
                  name="hasSurvey"
                  value={form.hasSurvey}
                />

                <Row>
                  <Col
                    sm={12}
                    lg={4}
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ImageUploader
                      register={register}
                      id="avatar"
                      value={form.avatar}
                      error={errors.avatar}
                      setValue={setValue}
                      readOnly={readOnly}
                    />
                  </Col>
                  <Col sm={12} lg={8} style={{ width: "100%" }}>
                    <Row>
                      <Col xs={12}>
                        <InputField
                          label="Nombre del Proyecto"
                          type="text"
                          id="projectName"
                          register={register}
                          error={errors.projectName}
                          //value={form.projectName}
                          required={!form.minifiedHeader}
                          readOnly={readOnly}
                          tabIndex="1"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={6}>
                        <InputField
                          label="Nombre"
                          type="text"
                          id="firstName"
                          register={register}
                          error={errors.firstName}
                          required={true}
                          //value={form.firstName}
                          width={100}
                          readOnly={readOnly}
                          tabIndex="2"
                        />
                      </Col>
                      <Col xs={12} sm={6}>
                        <InputField
                          label="Apellido"
                          type="text"
                          id="lastName"
                          register={register}
                          error={errors.lastName}
                          required={true}
                          //value={form.lastName}
                          width={100}
                          readOnly={readOnly}
                          tabIndex="3"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={readOnly ? 12 : 6}>
                        <InputField
                          label="Email"
                          id="email"
                          type="email"
                          register={register}
                          error={errors.email}
                          //value={form.email}
                          required={true}
                          readOnly={readOnly}
                          tabIndex="4"
                        />
                      </Col>
                      {!readOnly && (
                        <Col xs={12} sm={6}>
                          <InputField
                            label="Confirmación Email"
                            id="confirmEmail"
                            type="email"
                            register={register}
                            error={errors.confirmEmail}
                            required={true}
                            readOnly={readOnly}
                            tabIndex="5"
                            cancelPaste={true}
                          />
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col xs={12} sm={6}>
                        <InputField
                          label="Empresa/Institución"
                          type="text"
                          id="companyName"
                          register={register}
                          error={errors.companyName}
                          //value={form.companyName}
                          required={true}
                          readOnly={readOnly}
                          tabIndex="6"
                        />
                      </Col>
                      <Col xs={12} sm={6}>
                        <SelectField
                          options={StaffPositions}
                          id="staffPosition"
                          label="Cargo"
                          //value={form && form.staffPosition}
                          defaultOption="Elija un cargo"
                          register={register}
                          error={errors.staffPosition}
                          readOnly={readOnly}
                          tabIndex="7"
                        />
                      </Col>
                    </Row>
                    {!form.minifiedHeader && (
                      <>
                        <Row>
                          <Col xs={12} sm={6}>
                            <SelectField
                              options={States}
                              id="state"
                              label="Provincia"
                              //value={form && form.state}
                              defaultOption="Elija una provincia"
                              register={register}
                              error={errors.state}
                              readOnly={readOnly}
                              required={!form.minifiedHeader}
                              tabIndex="8"
                            />
                          </Col>
                          <Col xs={12} sm={6}>
                            <InputField
                              label="Localidad"
                              type="text"
                              id="city"
                              register={register}
                              error={errors.city && errors.city}
                              //value={form && (form.city.label || form.city)}
                              required={!form.minifiedHeader}
                              readOnly={readOnly}
                              tabIndex="9"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} sm={6}>
                            <InputField
                              label="Teléfono"
                              type="text"
                              id="phone"
                              register={register}
                              error={errors.phone}
                              //value={form.phone}
                              required={false}
                              readOnly={readOnly}
                              tabIndex="10"
                              icon={<PhoneLineIcon />}
                            />
                          </Col>
                          <Col xs={12} sm={6}>
                            <InputField
                              label="Sitio Web"
                              id="website"
                              type="link"
                              register={register}
                              error={errors.website}
                              //value={form.website}
                              required={false}
                              readOnly={readOnly}
                              tabIndex="11"
                              prefix="https://"
                              icon={<WebLineIcon />}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} sm={6}>
                            <InputField
                              label="LinkedIn"
                              type="link"
                              id="linkedin"
                              register={register}
                              error={errors.linkedin}
                              //value={form.linkedin}
                              required={false}
                              readOnly={readOnly}
                              tabIndex="12"
                              prefix="https://www.linkedin.com/"
                              icon={<LinkedinLineIcon />}
                            />
                          </Col>
                          <Col xs={12} sm={6}>
                            <InputField
                              label="Facebook"
                              type="link"
                              id="facebook"
                              register={register}
                              error={errors.facebook}
                              //value={form.facebook}
                              required={false}
                              readOnly={readOnly}
                              tabIndex="13"
                              prefix="https://www.facebook.com/"
                              icon={<FacebookLineIcon />}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} sm={6}>
                            <InputField
                              label="Instagram"
                              type="link"
                              id="instagram"
                              register={register}
                              error={errors.instagram}
                              //value={form.instagram}
                              required={false}
                              readOnly={readOnly}
                              tabIndex="14"
                              prefix="https://www.instagram.com/"
                              icon={<InstagramLineIcon />}
                            />
                          </Col>
                          <Col xs={12} sm={6}>
                            <InputField
                              label="X"
                              id="twitter"
                              type="link"
                              register={register}
                              error={errors.twitter}
                              //value={form.twitter}
                              required={false}
                              readOnly={readOnly}
                              tabIndex="15"
                              prefix="https://twitter.com/"
                              icon={<TwitterLineIcon />}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </Col>
                </Row>

                {!form.minifiedHeader && (
                  <>
                    {(!readOnly || !showPostulator) && (
                      <Row>
                        <Col xs={12}>
                          <HorizontalLine />
                        </Col>
                      </Row>
                    )}
                    {!readOnly && (
                      <Fragment>
                        <Row>
                          <Col xs={12}>
                            <CheckBoxField
                              id="showPostulator"
                              label="¿Estas postulando a alguien?"
                              secondary="De ser asi, tenes que completar estos datos"
                              onChange={handleShowPostulator}
                              error={errors.showPostulator}
                              register={register}
                              checked={form.showPostulator}
                              showRow={true}
                              readOnly={readOnly}
                              tabIndex="25"
                            />
                          </Col>
                        </Row>
                      </Fragment>
                    )}
                    {(form.showPostulator || showPostulator) && (
                      <Row>
                        <Col xs={12} md={6} lg={4}>
                          <InputField
                            label="Nombre y Apellido"
                            type="text"
                            id="postulatorFullName"
                            error={errors.postulatorFullName}
                            //value={form.postulatorFullName}
                            register={register}
                            required={showPostulator}
                            readOnly={readOnly}
                            tabIndex="26"
                          />
                        </Col>
                        <Col xs={12} md={6} lg={4}>
                          <InputField
                            label="Email"
                            type="email"
                            id="postulatorEmail"
                            register={register}
                            required={showPostulator}
                            error={errors.postulatorEmail}
                            //value={form.postulatorEmail}
                            readOnly={readOnly}
                            tabIndex="27"
                          />
                        </Col>
                        <Col xs={12} md={6} lg={4}>
                          <InputField
                            label="Teléfono"
                            type="text"
                            id="postulatorPhone"
                            register={register}
                            required={false}
                            error={errors.postulatorPhone}
                            //value={form.postulatorPhone}
                            readOnly={readOnly}
                            tabIndex="28"
                          />
                        </Col>
                      </Row>
                    )}
                  </>
                )}
              </Grid>
            )}
          </Section>

          <Section>
            <Header>
              <Title>Cuestionario</Title>
            </Header>
            <Grid style={{ width: "100%", padding: "35px" }}>
              <Row>
                <Col xs={12}>
                  <Questionnaire
                    sections={fields}
                    setValue={setValue}
                    register={register}
                    errors={errors}
                    readOnly={readOnly}
                    tabIndex="29"
                  />
                </Col>
              </Row>
              {(!readOnly || form.attachments) && (
                <Row>
                  <Col xs={12}>
                    <FileUploader
                      title={fileUploaderTitle}
                      id="attachments"
                      folder={form && `attachments/${form.identity}`}
                      register={register}
                      error={errors.attachments}
                      value={form.attachments}
                      setValue={setValue}
                      readOnly={readOnly}
                      multiple={true}
                      tabIndex="200"
                    />
                  </Col>
                </Row>
              )}
              {(!readOnly || form.videoLink) && (
                <Row>
                  <Col xs={12}>
                    <VideoPlayer
                      title={VideoLinkTitle}
                      register={register}
                      id="videoLink"
                      error={errors.videoLink}
                      value={form.videoLink}
                      readOnly={readOnly}
                      tabIndex="201"
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={12}>
                  <HorizontalLine />
                </Col>
              </Row>
              <Row>
                <FormToolBar
                  onUpdateForm={onUpdateForm}
                  onClose={onClose}
                  currentStatus={currentStatus}
                />
              </Row>
            </Grid>
          </Section>
        </EntryForm>
      </ComponentContainer>
    </>
  );
}
